import useSWRInfinite, {SWRInfiniteResponse} from "swr/infinite";
import WatsonApi from "../../../backends/WatsonApi";
import {useContext} from "react";
import {StoreContext} from "../../../stores/StoreLoader";
import {ReactiveContentItem} from "../../../stores/SchoolFeedStore";
import {mutate as globalMutate} from "swr"
import {useSchoolFeedUrlQuery} from "../../../hooks/useSchoolFeedData";

interface SchoolFeedInfiniteScrollResponse extends Omit<SWRInfiniteResponse, "data"> {
    contentItems: ReactiveContentItem[],
    showViewMore: boolean,
    getItemAtIndex: (idx: number) => Promise<ReactiveContentItem | null>,
    totalContentItems: number,
}

// seems like this is poorly typed, but string represents a search query which could be anything
type SchoolFeedInfiniteContentType = ISchoolFeedContentType | string;

export function getSchoolFeedInfiniteScrollUniqueKey(contentType: SchoolFeedInfiniteContentType) {
    return `organizations_schoolfeed_items_list_${contentType}`
}

export function buildCacheKey(pageIndex, contentType, followingUrlQuery, orgIdUrlQuery) {
    return JSON.stringify({
        key: getSchoolFeedInfiniteScrollUniqueKey(contentType),
        page: pageIndex,
        following: followingUrlQuery || "",
        urlOrgId: orgIdUrlQuery || "",
    })
}

export function useSchoolFeedContentPathQueryParams() {
    const {organizationStore, userStore} = useContext(StoreContext);
    const urlQuery = useSchoolFeedUrlQuery();
    const q = {}

    if (!urlQuery.orgId && !urlQuery.following) {
        if (organizationStore.organization.parent_id == "root") {
            q["organization__path__descendant_or_eq"] = organizationStore.organization.district?.path || organizationStore.organization.path;
        } else {
            q["exclude_sibling_descendants_of_path"] = organizationStore.organization.path;
            q["organization__path__descendant_or_eq"] = organizationStore.organization.district?.path || organizationStore.organization.path;
        }
    } else if (!urlQuery.following) {
        q["organization_id"] = urlQuery.orgId;
    }
    if (!userStore.editor) {
        q["published"] = true;
    }
    if (urlQuery.following) {
        q["following"] = true;
    }
    return q;
}

export const schoolFeedPageSize = 12;

export function useSchoolFeedInfiniteScroll(contentType: SchoolFeedInfiniteContentType,
                                            followingUrlQuery: string,
                                            orgIdUrlQuery: string,
                                            uniqueQueryParams: { [k: string]: any }): SchoolFeedInfiniteScrollResponse {
    const pathQueryParams = useSchoolFeedContentPathQueryParams();

    const {
        data,
        isLoading,
        isValidating,
        size,
        setSize,
        error,
        mutate
    } = useSWRInfinite((pageIndex, previousPageData) => {
        return buildCacheKey(pageIndex, contentType, followingUrlQuery, orgIdUrlQuery);
    }, async (key) => {
        const parsedKey = JSON.parse(key);
        const client = await WatsonApi();

        const q = {
            page_size: schoolFeedPageSize,
            expand: "organization",
            page: parsedKey.page + 1,
            ...uniqueQueryParams,
            ...pathQueryParams,
        }

        let result;
        if (parsedKey.urlOrgId) {
            result = await client.apis.organizations.organizations_schoolfeed_items_list({
                organization_pk: parsedKey.urlOrgId,
                ...q,
            });
        } else {
            result = await client.apis.schoolfeed_items.schoolfeed_items_list(q);
        }

        return {
            contentItems: result.obj.data.map(d => new ReactiveContentItem(d)),
            total: result.obj.meta.total,
        };
    }, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })

    /*
    This function is passed into the SocialItemModal on open, which means that it will not
    update when referenced outside variables update. Because of that, it reads from the
    cache directly, and updates the cache as necessary.
    */
    const getItemAtIndex = async function (idx) {
        const pageSize = uniqueQueryParams["page_size"] || schoolFeedPageSize;
        const page = Math.floor(idx / pageSize);
        const cacheKey = buildCacheKey(
            page,
            contentType,
            followingUrlQuery,
            orgIdUrlQuery,
        );
        const paginatedIndex = idx - (page * pageSize);

        // grab data from existing cache
        const data = await globalMutate(cacheKey);

        if (data) {
            return data.contentItems[paginatedIndex];
        } else {
            // if there's no cache for the current page, revalidate
            const result = await setSize(page + 1);
            if (result) {
                const data = result[page];
                return data.contentItems[paginatedIndex];
            }
        }
        return null;
    }

    const contentItems = data?.map(curr => curr.contentItems).flat() || [];
    const showViewMore = data?.[0].total > contentItems.length;
    const total = data?.[0].total;

    return {
        contentItems,
        totalContentItems: total,
        getItemAtIndex,
        showViewMore,
        isLoading,
        isValidating,
        size,
        setSize,
        error,
        mutate,
    }
}
