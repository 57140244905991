import {observer} from "mobx-react";
import React, {useContext} from "react";
import moment from "moment";
import WatsonApi from "../../backends/WatsonApi";
import Calendar, {currentMonthFormat} from "../utilities/CleverCalendar/calendar";
import {modalStore, StoreContext} from "../../stores/StoreLoader";
import {deleteContentItem} from "../blocks/TerracedGridTheme/Terrace";
import {ISchoolFeedUrlQueries} from "../../utils/SchoolBlocksUtilities";
import {InitialLoadingIndicatorWrapper} from "./InitialLoadingIndicatorWrapper";
import {ReactiveContentItem} from "../../stores/SchoolFeedStore";
import SafeRouter from "../utilities/SafeRouter";
import useSWRInfinite from "swr/infinite";
import {useSchoolFeedContentPathQueryParams} from "./hooks/useSchoolFeedInfiniteScroll";

const handleSelectEvent = (event, handleDelete) => {
    modalStore.addModal({
        type: "schoolBlocksEvent",
        event: event,
    });
};

export function getCacheKeyFromCurrentRangeUrlQuery(urlQuery: ISchoolFeedUrlQueries) {
    return JSON.stringify({
        start: urlQuery.currentRangeStart,
        end: urlQuery.currentRangeEnd,
        orgId: !urlQuery.orgId,
    });
}

const currentRangeUrlQueryFormat = "YYYY-MM-DD";

interface DateRange {
    start: moment.Moment;
    end: moment.Moment;
}
interface SchoolFeedItemListQuery {
    organization_id?: string;
    organization__path__descendant_or_eq?: string;
    exclude_sibling_descendants_of_path?: string;
    is_event: boolean;
    created_at__gte: string;
    created_at__lte: string;
    page_size: number;
    ordering: string;
    expand: string[];
    published?: boolean;

}
const SchoolFeedEventsDisplay: React.FC<{
    urlQuery: ISchoolFeedUrlQueries,
}> = observer((props) => {
    const {organizationStore, userStore} = useContext(StoreContext);
    const pathQueryParams = useSchoolFeedContentPathQueryParams()

    const handleRangeChange = async (key) => {
        const parsedKey = JSON.parse(key)


        let gte;
        let lte;

        if (parsedKey.start && parsedKey.end) {
            gte = parsedKey.start;
            lte = parsedKey.end;
        }
        else {
            gte = moment().startOf('month').format(currentRangeUrlQueryFormat)
            lte = moment().add(1, 'months').startOf('month').format(currentRangeUrlQueryFormat)
        }

        const client = await WatsonApi();

        const q: SchoolFeedItemListQuery = {
            'is_event': true,
            'created_at__gte': gte,
            'created_at__lte': lte,
            'page_size': 200,
            'ordering': 'created_at',
            'expand': ['organization'],
            ...pathQueryParams,
        }

        if (props.urlQuery.orgId) {
            const obj = await client.apis.organizations.organizations_schoolfeed_items_list({
                organization_pk: props.urlQuery.orgId,
                ...q,
            });
            return JSON.parse(obj.data).data.map(d => new ReactiveContentItem(d));
        } else {
            const obj = await client.apis.schoolfeed_items.schoolfeed_items_list(q);
            return JSON.parse(obj.data).data.map(d => new ReactiveContentItem(d));
        }
    };

    function useGetEvents () {
        const cacheKey = getCacheKeyFromCurrentRangeUrlQuery(props.urlQuery);
        const {data: events, isLoading, mutate, error} = useSWRInfinite(() => cacheKey, async (key) => handleRangeChange(key), {
            revalidateIfStale: false,
        });

        const returnEvents = events ? events[0] : []

        return {
            events: returnEvents,
            isLoading,
            mutate,
            error,
        }
    }

    const {events, isLoading, mutate, error} = useGetEvents();

    const handleDelete = async (contentItem) => {
        await deleteContentItem(contentItem);
        mutate();
    }

    const handleRangeUpdate = async (range: DateRange) => {
        await SafeRouter.setQueries(
            ["currentRangeStart", range.start.format(currentRangeUrlQueryFormat)],
            ["currentRangeEnd", range.end.format(currentRangeUrlQueryFormat)],
        );
    }

    return (
        <InitialLoadingIndicatorWrapper
            initialFetchIsLoading={false}
            dataSize={1}
            errorCode={error?.status}
            showInitialLoadingIndicator={false}
        >
            <Calendar
                events={events || []}
                loading={isLoading}
                onRangeChange={handleRangeUpdate}
                onEventClick={handleSelectEvent}
                aboveTheFold={true}
                expandedView={true}
                handleDeleteEvent={handleDelete}
                initialMonth={props.urlQuery.currentRangeStart ? moment(props.urlQuery.currentRangeStart, currentRangeUrlQueryFormat).add(3, 'days').format(currentMonthFormat) : undefined}
            />
        </InitialLoadingIndicatorWrapper>
    );
})

export default SchoolFeedEventsDisplay;
