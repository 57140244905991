import {useContext, useMemo} from "react";
import {getQueries, ISchoolFeedUrlQueries} from "../utils/SchoolBlocksUtilities";
import {StoreContext} from "../stores/StoreLoader";
import useSWR from "swr/immutable";
import WatsonApi from "../backends/WatsonApi";
import SafeRouter from "../components/utilities/SafeRouter";
import {IView} from "../stores/InterfaceStore";

export const contentTypes: ISchoolFeedContentType[] = ["news", "media", "files", "events", "coursework"];
export type IContentCount = { [k in ISchoolFeedContentType]: number };

interface SchoolFeedAnalyticsQuery {
    organization_id?: string;
    organization__path__descendant_or_eq?: string;
    exclude_sibling_descendants_of_path?: string;
    following?: string,
    published?: boolean;
}

export function useSchoolFeedUrlQuery(): ISchoolFeedUrlQueries {
    const {interfaceStore, organizationStore, userStore} = useContext(StoreContext);

    return useMemo(() => {
        // evaluates url query parameters and checks their integrity
        const queries = getQueries(interfaceStore.currentFullUrl)
        if (queries.currentView !== "shop" &&
            ((queries.type && !contentTypes.includes(queries.type as never | ISchoolFeedContentType)) || // query type is provided but it's invalid
                (!queries.type && !queries.q)) // no query type is provided, and we're not searching (allow no type when searching)
            && (interfaceStore.view === IView.SCHOOLFEED_PAGE || interfaceStore.view === IView.DEFAULT)
        ) {
            queries.type = "news";
        }
        if (userStore.id && (
            organizationStore.isMyCleverFeed ||
            queries.following)
        ) {
            queries.following = "true";
        } else {
            delete queries.following
        }
        if (!queries.currentView) {
            queries.currentView = "content";
        }
        return queries;
    }, [interfaceStore.currentFullUrl])
}

export function useSchoolFeedData() {
    const {organizationStore, userStore} = useContext(StoreContext);
    const urlQuery = useSchoolFeedUrlQuery();

    function buildKey() {
        if (organizationStore.currentOrganization.type === "collection" &&
            organizationStore.currentOrganization.collectionRules?.some(rule => rule.collection_of === "people")) {
            return null;
        }
        return `getAnalytics?include_everything=${urlQuery.orgId}${urlQuery.following}`;
    }

    const {data: contentCounts, isLoading, isValidating} = useSWR(buildKey(), async () => {
        const client = await WatsonApi();
        const q: SchoolFeedAnalyticsQuery = {
            following: urlQuery.following,
        }
        if (!urlQuery.orgId && !urlQuery.following) {
            if (organizationStore.organization.parent_id == "root") {
                q["organization__path__descendant_or_eq"] = organizationStore.organization.district?.path || organizationStore.organization.path;
            } else {
                q["exclude_sibling_descendants_of_path"] = organizationStore.organization.path;
                q["organization__path__descendant_or_eq"] = organizationStore.organization.district?.path || organizationStore.organization.path;
            }
        } else if (!urlQuery.following) {
            q["organization_id"] = urlQuery.orgId;
        }
        if (!userStore.editor) {
            q["published"] = true;
        }

        let response;
        if (urlQuery.orgId) {
            response = await client.apis.organizations.organizations_schoolfeed_analytics_list({
                organization_pk: urlQuery.orgId,
                ...q,
            });
        } else {
            response = await client.apis.schoolfeed_analytics.schoolfeed_analytics_list(q);
        }

        const analytics = response.obj[0];
        return {
            "news": analytics.has_news,
            "events": analytics.has_event,
            "files": analytics.has_file,
            "media": analytics.has_media,
            "coursework": analytics.has_coursework,
        }
    });

    async function viewInternalSchoolFeed(org) {
        await SafeRouter.setQuery("following", undefined);
        await SafeRouter.setQuery("orgId", org.id);
        await SafeRouter.setQuery("currentView", "content");
    }

    return {
        contentCounts: contentCounts,
        isLoadingContentCounts: isLoading || isValidating,
        urlQuery,
        viewInternalSchoolFeed,
    }
}
